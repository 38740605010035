import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  combineLatest,
  map,
  mergeAll,
  startWith,
  Subject,
  mergeMap,
  toArray,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TitleComponent } from 'src/app/components/layout/title/title.component';
import { CardTitleComponent } from 'src/app/components/shared/card-title/card-title.component';
import { CardComponent } from 'src/app/components/shared/card/card.component';
import { ColComponent } from 'src/app/components/shared/col/col.component';
import { RowComponent } from 'src/app/components/shared/row/row.component';
import { Inquiry, INQUIRY_STATUSES } from 'src/app/models/inquiry';
import { ActionButton } from 'src/app/models/utils';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { InquiryService } from 'src/app/shared/services/inquiry.service';
import { ExpanderAnimationComponent } from 'src/app/components/shared/expander-animation/expander-animation.component';
import {
  InquiryStatusBadgeComponent,
  inquiryStatusMap,
} from 'src/app/components/matchmaking/inquiry-status-badge/inquiry-status-badge.component';
import { ProducerInquiryCardComponent } from 'src/app/components/matchmaking/producer-inquiry-card/producer-inquiry-card.component';
import { LoadingComponent } from 'src/app/components/layout/loading/loading.component';
import { Loading } from 'src/app/pipes/loading.pipe';
import { OfferService } from 'src/app/shared/services/offer.service';
import { Offer } from 'src/app/models/offer';
import { EmptyPlaceholderComponent } from 'src/app/components/shared/empty-placeholder/empty-placeholder.component';
import { LocationService } from 'src/app/shared/services/location.service';

interface ExtendedInquiry extends Inquiry {
  offers: Offer[];
}

@Component({
  selector: 'app-producer-inquiries',
  templateUrl: './producer-inquiries.component.html',
  styleUrls: ['./producer-inquiries.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AppMaterialModule,
    TitleComponent,
    CardComponent,
    CardTitleComponent,
    RowComponent,
    ColComponent,
    ExpanderAnimationComponent,
    InquiryStatusBadgeComponent,
    ProducerInquiryCardComponent,
    ReactiveFormsModule,
    LoadingComponent,
    Loading,
    EmptyPlaceholderComponent,
  ],
})
export class ProducerInquiriesComponent implements OnInit {
  route = inject(ActivatedRoute);
  inquiryService = inject(InquiryService);
  locationService = inject(LocationService);
  offerService = inject(OfferService);
  inquiries$ = new Subject<ExtendedInquiry[]>();
  getLocationById = (id: string) => this.locationService.getLocationById(id);
  locations$ = this.locationService.getLocations();
  INQUIRY_STATUSES = INQUIRY_STATUSES;
  inquiryStatusMap = inquiryStatusMap;
  filters = new FormGroup({
    locationFilter: new FormControl(''),
    onlyWithOffers: new FormControl(false),
    inquiryStatus: new FormControl<Inquiry['status'][]>([
      'OPEN',
      'VERIFIED',
      'SAMPLE_PENDING',
    ]),
  });
  showFilters = false;

  constructor() {
    this.route.data.pipe(takeUntilDestroyed()).subscribe((data) => {
      const initialOfferFilterValue = data['onlyShowInquiriesWithOffers'];
      const initialStatusFilterValue = data['status'];

      setTimeout(() => {
        this.filters.controls.onlyWithOffers.setValue(
          Boolean(initialOfferFilterValue)
        );

        if (initialStatusFilterValue) {
          this.filters.controls.inquiryStatus.setValue(
            initialStatusFilterValue
          );
        }
      });
    });
  }

  ngOnInit() {
    this.fetchInquiries();
  }

  fetchInquiries() {
    this.inquiryService
      .getInquiries()
      .pipe(
        mergeAll(),
        mergeMap((inquiry) =>
          this.offerService
            .getOffersForInquiry(inquiry.id)
            .pipe(map((offers) => ({ ...inquiry, offers })))
        ),
        toArray()
      )
      .subscribe((result) => {
        this.inquiries$.next(result);
      });
  }

  filteredInquiries$ = combineLatest({
    inquiries: this.inquiries$,
    filter: this.filters.valueChanges.pipe(
      startWith(this.filters.getRawValue())
    ),
  }).pipe(
    map(({ inquiries, filter }) => {
      let filteredByLocation: ExtendedInquiry[];

      if (filter.locationFilter) {
        filteredByLocation = inquiries.filter(
          (inquiry) => inquiry.locationId === filter.locationFilter
        );
      } else {
        filteredByLocation = inquiries;
      }

      const filteredByStatus = inquiries.filter((inquiry) =>
        filter.inquiryStatus.includes(inquiry.status)
      );

      if (filter.onlyWithOffers) {
        return filteredByStatus.filter((inquiry) => inquiry.offers?.length);
      } else {
        return filteredByStatus;
      }
    })
  );

  actionButton: ActionButton = {
    label: 'Neue Anfrage erstellen',
    icon: 'restore_from_trash',
    url: '/neue-entsorgungsanfrage/neu',
  };

  isQuotationExpired(quotationDeadline: string): boolean {
    if (!quotationDeadline) {
      return false;
    }
    let currentDate: Date = new Date();
    let deadlineDate: Date = new Date(quotationDeadline);
    return deadlineDate < currentDate;
  }

  isQuotationValid(quotationDeadline: string): boolean {
    if (!quotationDeadline) {
      return true;
    }
    let currentDate: Date = new Date();
    let deadlineDate: Date = new Date(quotationDeadline);
    return deadlineDate >= currentDate;
  }
}
