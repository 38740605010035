<app-expandable-card [initiallyOpen]="true">
  <app-row class="px-3">
    <app-col xs="12">
      <app-inquiry-status-badge
        class="badge"
        [status]="inquiry.status"
        [offers]="numberOfOffers$ | async"
      />
      <app-card-title>
        <span class="mr-3" *ngIf="inquiry.created">
          {{ inquiry.created | date: 'shortDate' }}
        </span>
        <span class="mr-3" *ngIf="location$ | async as location">
          {{ location.label }}
        </span>
        <span class="mr-3">
          {{ mapTypeToLabel(inquiry.type) }}
        </span>
        <span class="mr-3" *ngIf="inquiry.preferredContractDuration !== null">
          {{
            convertPreferredContractDuration(inquiry.preferredContractDuration)
          }}
        </span>
      </app-card-title>
    </app-col>
  </app-row>

  <div content class="mouseflow-ignore">
    <app-row class="px-3">
      <app-col class="d-flex align-center">
        <app-round-icon class="mr-2 icon">access_time</app-round-icon>
        Angelegt&nbsp;
        <span *ngIf="user$ | async as user">
          von {{ user.firstname }} {{ user.lastname }}&nbsp;
        </span>
        um {{ inquiry.created | date: 'shortTime' }}
      </app-col>

      <app-col class="d-flex align-center" *ngIf="inquiry.quotationDeadline">
        <app-round-icon class="mr-2 icon">access_alarm</app-round-icon>
        Gültig bis: {{ inquiry.quotationDeadline | date: 'shortDate' }}
      </app-col>
    </app-row>

    <app-row class="px-3">
      <app-col
        class="d-flex align-center"
        *ngIf="inquiry.transportByProducer === false"
      >
        <app-round-icon class="mr-2 icon">local_shipping</app-round-icon>
        <ng-container> Transport benötigt </ng-container>
      </app-col>

      <app-col
        class="d-flex align-center"
        *ngIf="inquiry.transportByProducer === true"
      >
        <app-round-icon class="mr-2 icon">trolley</app-round-icon>
        <ng-container> Kunde übernimmt Anlieferung </ng-container>
      </app-col>
    </app-row>

    <app-row
      class="px-3"
      *ngIf="
        inquiry.preferredContractDuration !== null &&
        inquiry.preferredContractDuration !== 'P0Y'
      "
    >
      <app-col class="d-flex align-center">
        <app-round-icon class="mr-2 icon">history_edu</app-round-icon>
        Ausgeschriebene Vertragslaufzeit:
        {{
          convertPreferredContractDuration(inquiry.preferredContractDuration)
        }}
      </app-col>
    </app-row>

    <app-row class="px-3">
      <app-col class="d-flex align-center">
        <app-waste-pills [inquiryElements]="inquiry.inquiryElements" />
      </app-col>
    </app-row>

    <app-row class="mt-3 px-3">
      <app-col
        *ngIf="inquiry.status === 'OPEN' || inquiry.status === 'VERIFIED'"
      >
        <button (click)="removeInquiry(inquiry.id)" mat-button color="primary">
          Zurückziehen
        </button>
      </app-col>

      <app-col *ngIf="inquiry.status === 'DRAFT'">
        <button (click)="removeInquiry(inquiry.id)" mat-button color="primary">
          Löschen
        </button>
      </app-col>

      <app-spacer></app-spacer>

      <app-col>
        <a
          [routerLink]="['/neue-entsorgungsanfrage', 'neu', inquiry.id]"
          mat-stroked-button
          color="primary"
          *ngIf="inquiry.status === 'OPEN' || inquiry.status === 'VERIFIED'"
        >
          Duplizieren
        </a>
      </app-col>

      <app-col *ngIf="inquiry.quotationDeadline !== null">
        <button
          (click)="extendInquiryDeadline()"
          mat-stroked-button
          color="primary"
          *ngIf="inquiry.status === 'OPEN' || inquiry.status === 'VERIFIED'"
        >
          Verlängern
        </button>
      </app-col>

      <app-col>
        <button
          (click)="showInquiryDetails(inquiry.id)"
          mat-stroked-button
          color="primary"
        >
          Details anzeigen
        </button>
      </app-col>

      <app-col *ngIf="inquiry.status === 'DRAFT'">
        <a
          [routerLink]="['/neue-entsorgungsanfrage', 0, 2, inquiry.id]"
          mat-raised-button
          color="primary"
        >
          Bearbeiten
        </a>
      </app-col>

      <app-col *ngIf="numberOfOffers$ | async as numberOfOffers">
        <button
          mat-raised-button
          color="primary"
          [routerLink]="'/erzeuger-angebote/' + inquiry.id"
        >
          {{
            numberOfOffers
              | i18nPlural: { '=1': '# Angebot', other: '# Angebote' }
          }}
          ansehen
        </button>
      </app-col>
    </app-row>
  </div>
</app-expandable-card>
